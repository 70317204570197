import React, { Component } from "react";
import "./App.css";
import InputData from "./InputData";
import HalUI from "@diaas/hal-ui";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { browseKey: 0, browseParams: null };
    this.triggerBrowse = this.triggerBrowse.bind(this);
  }

  triggerBrowse(url, headers) {
    this.setState(prevState => ({
      browseKey: prevState.browseKey + 1,
      browseParams: {
        url:
          ((url.includes("http://") || url.includes("https://")) && url) ||
          `http://${url}`,
        headers: headers.reduce(
          (acc, header) =>
            (header.key &&
              header.value &&
              Object.assign({}, acc, { [header.key]: header.value })) ||
            acc,
          {}
        )
      }
    }));
  }

  render() {
    const { browseParams, browseKey } = this.state;
    return (
      <div className="app">
        <InputData triggerBrowse={this.triggerBrowse} />
        {browseParams && (
          <div className="hal-ui-container">
            <HalUI
              url={browseParams.url}
              headers={browseParams.headers}
              key={"browse-hal-ui-" + browseKey}
            />
          </div>
        )}
      </div>
    );
  }
}

export default App;
